<template>
  <van-action-sheet v-model="show" title="付款" @close="onCancel">
    <div class="content">
      <van-radio-group v-model="radio">
        <van-cell-group>
          <van-cell title="支付宝支付" icon="alipay" clickable @click="radio = '1'">
            <template #right-icon>
              <van-radio name="1" />
            </template>
          </van-cell>
          <van-cell title="微信支付" icon="wechat-pay" clickable @click="radio = '2'">
            <template #right-icon>
              <van-radio name="2" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <van-cell>
        <van-button type="primary" block @click="surePay">{{ radio === '1' ? '支付宝' : '微信' }}支付￥{{ payAmount }}</van-button>
      </van-cell>
    </div>
  </van-action-sheet>
</template>

<script>
export default {
  name: "SelectPayType",
  props: {
    showPayLayer: {
      type: Boolean,
      default: false
    },
    payAmount: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      radio: "1",
      show: false
    }
  },
  created() {
    if (this.showPayLayer) {
      this.show = true;
    }
  },
  methods: {
    onCancel() {
      this.$emit('close', true);
    },
    surePay() {
      this.$emit('pay', this.radio);
    }
  },
  watch: {
    showPayLayer(newVal) {
      this.show = newVal;
    }
  }
}
</script>

<style lang="scss" scoped>
.van-action-sheet {
  text-align: left;
  border-radius: 6px 6px 0 0 !important;

  /deep/ .van-action-sheet__content {
    .van-cell {
      .van-cell__left-icon {
        font-size: 24px;
      }

      .van-icon-alipay {
        color: #53b3f0;
      }

      .van-icon-wechat-pay {
        color: #4fcb77;
      }

      .van-radio__icon--checked {
        .van-icon {
          border-color: #ff98a1;
          background-color: #ff98a1;
        }
      }

      .van-button {
        height: 38px;
        border-radius: 30px;
        border-color: #ff98a1;
        background-color: #ff98a1;
      }
    }
  }
}
</style>